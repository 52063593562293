import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO
      title="404: Not found"
      description="Страница не найдена"
      canonical="/404"
    />
    <Container>
      <h1>404 NOT FOUND</h1>
      <p>Такой страницы на этом сайте нет...</p>
    </Container>
  </Layout>
)

export default NotFoundPage

const Container = styled.div`
  margin: 0 auto;
  max-width: 280px;
  margin-top: 5rem;
`
